
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <b-row v-if="role == 'Merchant' || role == 'Cashier'">
      <b-col lg="4" md="4" sm="4">
        <b-card class="card-icon text-center mb-30">
          <i class="i-Arrow-Down"></i>
          <p class="text-muted mt-2 mb-2">Redeemable Value </p>
          <p class="text-primary text-24 line-height-1 m-0">{{ merchantStats.pointsValue | number('0,0') }}</p>
        </b-card>
      </b-col>
      <b-col lg="4" md="4" sm="4">
        <b-card class="card-icon text-center mb-30">
          <i class="i-Arrow-Down"></i>
          <p class="text-muted mt-2 mb-2">Total Loyalty Points Awarded (Pending) </p>
          <p class="text-primary text-24 line-height-1 m-0">{{ merchantStats.pointsAccrued | number('0,0') }}</p>
        </b-card>
      </b-col>
      <b-col lg="4" md="4" sm="4">
        <b-card class="card-icon text-center mb-30">
          <i class="i-Arrow-Down"></i>
          <p class="text-muted mt-2 mb-2">Loyalty Points Awarded Today</p>
          <p class="text-primary text-24 line-height-1 m-0">{{ merchantStats.pointsAccruedToday | number('0,0') }}</p>
        </b-card>
      </b-col>
      <b-col lg="4" md="4" sm="4">
        <b-card class="card-icon text-center mb-30">
          <i class="i-Arrow-Up"></i>
          <p class="text-muted mt-2 mb-2">Loyalty Points Redeemed Today</p>
          <p class="text-primary text-24 line-height-1 m-0"> {{ merchantStats.redeemedToday | number('0,0') }}</p>
        </b-card>
      </b-col>
      <b-col lg="4" md="4" sm="4"></b-col>
      <b-col lg="4" md="4" sm="4">
        <div class="mb-3">
          <b-button style="height: 60px;" class="btn btn-danger float-right btn-block" @click="openAwardPointsForm">Award Points</b-button>
        </div>
        <br/>
        <div>
          <b-button style="height: 60px" class="btn btn-success float-right btn-block"  @click="openRedeemPointsForm" v-b-modal.redeem-points>Customer Redemption </b-button>
        </div>


      </b-col>
    </b-row>

    <b-row v-else>
      <b-col lg="6" md="6" sm="6">
        <b-card class="card-icon text-center mb-30">
          <i class="i-Arrow-Down"></i>
          <p class="text-muted mt-2 mb-2">Points Accrued Today</p>
          <p class="text-primary text-24 line-height-1 m-0">{{ loyaltyStats.pointsAccruedToday | number('0,0') }}</p>
        </b-card>
      </b-col>
      <b-col lg="6" md="6" sm="6">
        <b-card class="card-icon text-center mb-30">
          <i class="i-Arrow-Up"></i>
          <p class="text-muted mt-2 mb-2">Redeemed Value Today</p>
          <p class="text-primary text-24 line-height-1 m-0"> {{ loyaltyStats.redeemedToday | number('0,0') }}</p>
        </b-card>
      </b-col>
    </b-row>

    <hr/>
    <div class="row">
      <div class="col-md-6">
        <h4>Filters:</h4>
      </div>
      <div class="col-md-6">
        <button style="margin-left: 10px;" type="button" class="btn btn-primary float-right" @click="clearFilter">Clear All</button>
      </div>
    </div>

    <div class="row" style="margin-bottom: 20px;">
      <div class="col-md-6">
        <div>
          By date:
        </div>

        <date-picker   :lang="lang" v-model="start_date" valueType="format" :first-day-of-week="1"></date-picker> to
        <date-picker   :lang="lang" v-model="end_date" valueType="format" :first-day-of-week="1"></date-picker> &nbsp;&nbsp;
        <button type="button" class="btn btn-danger" @click="getTransactionsData">Filter</button>

      </div>
      <div class="col-md-2">
        <div>
          By Transaction type:
        </div>
        <select class="form-control" v-model="transaction_type" @change="getTransactionsData">
            <option value="all"> All </option>
            <option :value="t_type" v-for="t_type in transaction_types">{{ t_type }}</option>
        </select>
      </div>
      <div class="col-md-2">
        <div>
          By Outlet:
        </div>
        <select class="form-control" v-model="outlet" @change="getTransactionsData">
            <option v-if="role != 'Merchant'" value="" > All </option>
            <option :value="shop" v-for="shop in outlets"> {{ shop.name }}</option>
        </select>
      </div>
      <div class="col-md-2" v-if="role != 'Merchant' && role != 'Cashier' ">
        <div>
          By Loyalty Rule:
        </div>
        <select class="form-control" v-model="loyaltyRuleId" @change="getTransactionsData">
          <option value="0" > All </option>
          <option :value="rule.id" v-for="rule in rules"> {{ rule.ruleName }}</option>
        </select>
      </div>
    </div>

    <br/>

    <div v-if="role == 'Merchant' || role == 'Cashier' ">
      <b-table striped  :items="transactions" :fields="columns_merchant">
        <template v-slot:cell(customer)="data">
          <span>{{ data.value.name }}</span>
        </template>

        <template v-slot:cell(outlet)="data">
          <span>{{ data.value.name }}</span>
        </template>
        <template v-slot:cell(amount)="data">
          <span>KES. {{ data.value }}</span>
        </template>
        <template v-slot:cell(transactionType)="data">
            <span  class="badge badge-danger"  v-if="data.value == 'ACCRUAL'">
                  Accrual
                </span>
              <span class="badge badge-success"  v-else >
                  Redemption
            </span>
        </template>

        <template v-slot:cell(transactionApprovalStatus)="data">
          <div v-if="data.value != null" >
							<span  class="badge badge-success"  v-if="data.value == 'APPROVED'" >
								Accepted
							</span>
            <span  class="badge badge-warning"  v-else-if="data.value == 'PENDING'" >
								Pending
							</span>

            <span class="badge badge-danger"  v-else >
								Reversed
							</span>
          </div>
          <div v-else>
							<span   class="badge badge-info" >
								Accepted
							</span>
          </div>
        </template>

        <template v-slot:cell(options)="data">

          <b-dropdown text="options">
            <b-dropdown-item href="#" v-if="data.item.transactionApprovalStatus == 'REVERSED'"  @click="openReverseTransactionModal(data.item)">Reverse</b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </div>
    <div v-else style="overflow-x: scroll;">
      <b-table striped hover :items="transactions" :fields="columns">

        <!--<template #cell(no)="data">
          {{ data.index + 1 }}
        </template>-->

        <template v-slot:cell(customer)="data">
          <a style="text-decoration: underline; font-weight: bolder;" :href="'/apps/customer/'+data.item.customer.id" v-if="data.value.name">
            {{ data.value.name }}
          </a>
        </template>

        <template v-slot:cell(transactionDate)="data">
         {{ data.item.created }}
        </template>

        <template v-slot:cell(outlet)="data">
          <span v-if="data.value.name">{{ data.value.name }}</span>
        </template>
        <template v-slot:cell(amount)="data">
          <span>KES. {{ data.value | number('0,0') }}</span>
        </template>
        <template v-slot:cell(loyaltyPoints)="data">
          <span v-if="data.item.transactionApprovalStatus == 'REVERSED' "> -{{ data.value | number('0,0') }}</span>
          <span>{{ data.value | number('0,0') }}</span>
        </template>
        <template v-slot:cell(transactionType)="data">
          <span  class="badge badge-danger"  v-if="data.value == 'ACCRUAL'">
                              Accrual
                          </span>
            <span class="badge badge-success"  v-else >
                              Redemption
          </span>
        </template>
        <template v-slot:cell(user)="data">
          <span v-if="data.item.user">{{ data.item.user.name }}</span>
        </template>

        <template v-slot:cell(transactionApprovalStatus)="data">
          <div v-if="data.value != null" >
							<span  class="badge badge-success"  v-if="data.value == 'APPROVED'" >
								Accepted
							</span>
            <span  class="badge badge-warning"  v-else-if="data.value == 'PENDING'" >
								Pending
							</span>

            <span class="badge badge-danger"  v-else >
								Reversed
							</span>
          </div>
          <div v-else>
							<span   class="badge badge-info" >
								Accepted
							</span>
          </div>
        </template>

        <template v-slot:cell(options)="data">
          <!-- `data.value` is the value after formatted by the Formatter -->
          <b-dropdown text="options">
            <b-dropdown-item href="#" @click="openReverseTransactionModal(data.item)">Reverse</b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </div>

    <div class="row">
      <div class="col-md-12">
        <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
          <ul class="pagination justify-content-center">
            <li v-if="pagination.current_page > 1" class="page-item"><a @click.prevent="changePage(pagination.current_page - 1)" class="page-link" href="#">Previous</a></li>
            <li v-for="page in pagesNumber"
                v-bind:class="[ page == isActived ? 'active' : '']" class="page-item"><a @click.prevent="changePage(page - 1)" class="page-link" href="#">{{ page }}</a></li>
            <li v-if="pagination.current_page < pagination.last_page"  class="page-item"><a  @click.prevent="changePage(pagination.current_page + 1)"class="page-link" href="#">Next</a></li>
          </ul>
        </nav>
      </div>
    </div>

    <b-modal id="reverse-transaction" :title="'Reverse transaction for '+transaction.customer.name +', loyalty points: '+this.transaction.loyaltyPoints" @cancel="true" @ok="reverseCustomerTransaction"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">
          <div class="row" >

            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Comments:</label> <br/>
                <div >
                  <textarea v-model="reversal_comments" class="form-control" style="width: 100%;" rows="5"></textarea>
                </div>
              </div>

            </div>
          </div>
        </div>
      </form>
    </b-modal>
    <b-modal id="award-points" title="Award Points" hide-footer   centered>
      <form action="#" class="form-horizontal"  method="post" v-on:submit.prevent="validateBeforeSubmitAwardPoints">
        <input type="hidden" name="_token" />
        <div class="form-body">
          <div class="row" >
            <div class="col-md-12">
              <div class="alert alert-danger">
                {{ awardErrorMessage }}
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group ">
                <label :class="{ 'text-danger': $v.awardPoints.customerPhoneNumber.$error }">Enter Phone number:</label> <br/>
                <div >
                  <input v-model.trim="$v.awardPoints.customerPhoneNumber.$model" class="form-control" type="text" />
                  <div class="text-danger" v-if="!$v.awardPoints.customerPhoneNumber.required && $v.awardPoints.customerPhoneNumber.$dirty">Phone number is required</div>
                </div>
              </div>

              <div v-show="phoneNumberVerified">
                <div class="form-group ">
                  <label :class="{ 'text-danger': $v.awardPoints.amount.$error }">Amount:</label> <br/>
                  <div >
                    <input v-model.trim="$v.awardPoints.amount.$model" class="form-control" type="text"   />
                    <div class="text-danger" v-if="!$v.awardPoints.amount.required && $v.awardPoints.amount.$dirty">Amount field is required</div>
                  </div>
                </div>

                <div class="form-group ">
                  <label :class="{ 'text-danger': $v.awardPoints.referenceNo.$error }">Receipt Code:</label> <br/>
                  <div >
                    <input class="form-control" type="text"  v-model.trim="$v.awardPoints.referenceNo.$model" />
                    <div class="text-danger" v-if="!$v.awardPoints.referenceNo.required && $v.awardPoints.referenceNo.$dirty">Receipt code is required</div>
                  </div>
                </div>
              </div>

              <div class="form-group " >
                <button type="submit" class="btn btn-success btn-block"> Submit</button>
              </div>

            </div>
          </div>
        </div>
      </form>
    </b-modal>
    <b-modal id="redeem-points" title="Redeem From E-purse" hide-footer   centered>
      <div class="mt-4 mb-4">
        <form-wizard ref="personalDetails" stepSize="sm" title="" subtitle="" finishButtonText="Confirm" action="#" v-on:submit.prevent="validateBeforeSubmitRedemption" @on-complete="validateBeforeSubmitRedemption" @onNextStep="nextStep" @onPreviousStep="previousStep">
          <tab-content title="Step 1: Phone Number" :before-change="sendVerificationCodeToCustomer"
                       icon="ti-user" :selected="true" >

            <div class="form-group ">
              <label :class="{ 'text-danger': $v.redemption.phone_number.$error }">Enter Phone number:</label> <br/>
              <div >
                <input v-model.trim="$v.redemption.phone_number.$model" class="form-control" type="text" />
                <div class="text-danger" v-if="!$v.redemption.phone_number.required && $v.redemption.phone_number.$dirty">Phone number is required</div>
              </div>
            </div>
          </tab-content>
          <tab-content title="Step 2: Verification Code" :before-change="verifyVcode"
                       icon="ti-user" >

            <div class="mt-5 mb-5">
              <div class="d-flex justify-content-center">
                <CodeInput  :fields="4" :loading="false" class="input " v-on:change="onChange" v-on:complete="onComplete" />
              </div>
            </div>
          </tab-content>
          <tab-content title="Step 3: Amount"
                       icon="ti-user"  >
            <div >
              <div class="form-group ">
                <label :class="{ 'text-danger': $v.redemption.redemptionValue.$error }"> Redemption amount (From E-purse):</label> <br/>
                <div >
                  <input v-model.trim="$v.redemption.redemptionValue.$model" class="form-control" type="text"   />
                  <div class="text-danger" v-if="!$v.redemption.redemptionValue.required && $v.redemption.redemptionValue.$dirty">Amount field is required</div>
                </div>
              </div>

              <div class="form-group ">
                <label :class="{ 'text-danger': $v.redemption.redemptionValue.$error }"> Cash:</label> <br/>
                <div >
                  <input v-model="redemption.cashValue" class="form-control" type="text"   /> <br/>
                  <small class="form-text text-muted">(Balance paid through (cash, m-pesa, card etc)</small>
                </div>
              </div>

              <div class="form-group ">
                <label :class="{ 'text-danger': $v.redemption.reference.$error }">Receipt Code:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model.trim="$v.redemption.reference.$model" />
                  <div class="text-danger" v-if="!$v.redemption.reference.required && $v.redemption.reference.$dirty">Receipt code is required</div>
                </div>
              </div>
            </div>
          </tab-content>
        </form-wizard>
      </div>
    </b-modal>

  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";
  import {FormWizard, TabContent} from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import Multiselect from 'vue-multiselect'
  import Button from "../../components/button/Button";
  import { required,requiredIf} from 'vuelidate/lib/validators'
  import CodeInput from "vue-verification-code-input"

export default {
  data() {
    return {
      customerVerificationCode:"",
      awardPointsForm:false,
      redeemPointsForm: false,
      verificationCode:"",
      awardErrorMessage:"",
      reversal_comments:"",
      disabled:false,
      isLoading: false,
      fullPage: true,
      pagination: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      },
      loyaltyStats:{
        totalPoints:0,
        pointsAccruedToday:0,
        totalPointsToday:0,
        redeemedToday:0,
      },
      awardPoints:{
        customerPhoneNumber:"",
        loyaltyId:"",
        referenceNo:"",
        amount:"",
        outletId:"",
        userId:""
      },
      redemption:{
        redemptionValue:"",
        cashValue:0,
        phone_number:"",
        loyaltyId:"",
        user_id:"",
        reference:""
      },
      phoneNumberVerified:false,
      merchantStats:{
        pointsValue:0,
        pointsAccrued:0,
        pointsAccruedToday:0,
        redeemedToday:0
      },
      lang: {
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
        placeholder: {
          date: 'Select Date',
          dateRange: 'Select Date Range'
        }
      },
      offset: 4,
      columns:["customer","outlet","amount","transactionDate","loyaltyPoints","transactionType",{
        key: 'user',
        label: 'Transacted By',
        sortable: false
      },"transactionApprovalStatus","narration","referenceNo",{
        key: 'reversal_comments',
        label: 'REVERSAL COMMENTS',
        sortable: false
      },"options"],
      columns_merchant:["transactionDate","customer","narration","outlet","amount","transactionDate","loyaltyPoints","transactionType","transactionApprovalStatus","referenceNo","options"],
      transactions:[],
      start_date:"all",
      end_date:"all",
      outlet:null,
      loyaltyRuleId:0,
      outlets:[],
      transaction:{
        customer:{
          name:""
        },
        loyaltyPoints:0
      },
      verificationCodeVerified: false,
      transaction_type:"all",
      approval_type:"ALL",
      transaction_types:["Accrual","Redemption"],
      rules:[]
      //v-if="data.item.transactionApprovalStatus == 'PENDING'"
    };
  },
  validations: {
    awardPoints:{
      customerPhoneNumber:{
        required: requiredIf(function () {
          return this.isAwardPoints
        })
      },
      referenceNo:{
        required: requiredIf(function () {
          return this.isPhoneNumberVerified && this.isAwardPoints
        })
      },
      amount:{
        required: requiredIf(function () {
          return this.isPhoneNumberVerified && this.isAwardPoints
        })
      },
    },
    redemption:{
      redemptionValue:{
        required: requiredIf(function () {
          return this.isRedeemPoints && this.isVcodeVerified
        })
      },
      phone_number:{
        required: requiredIf(function () {
          return this.isRedeemPoints
        })
      },
      reference:{
        required: requiredIf(function () {
          return this.isRedeemPoints && this.isVcodeVerified
        })
      }
    },
  },
  computed:{
    isActived: function () {
      return this.pagination.current_page + 1;
    },
    pagesNumber: function () {
      if (!this.pagination.to) {
        return [];
      }
      var from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset * 2);
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    },
    permissions(){
      return localStorage.getItem("permissions");
    },
    role(){
      return localStorage.getItem("role");
    },
    isPhoneNumberVerified() {
      return  this.phoneNumberVerified
    },
    isVcodeVerified() {
      return  this.verificationCodeVerified
    },
    isRedeemPoints(){
      return this.redeemPointsForm;
    },
    isAwardPoints(){
      return this.awardPointsForm;
    }
  },
  components: {
    FormWizard,
    Button,
    Loading,
    DatePicker,
    Multiselect,
    TabContent,
    CodeInput
  },
  mounted (){
    if(this.role == "Merchant" || this.role == "Cashier"){
        this.getOutletByMerchant();
    }
    else{
      this.getOutletData();
      this.getLoyaltyStatsData();
      this.getRulesData();
    }

  },
  methods: {
    ...mapActions(["getRules","redeemPointsWebPortal","sendVerificationCode","awardCustomerPointsWebPortal","getLoyaltyTransactions", "getOutlets","getLoyaltyStats","reverseTransaction","getOutletsByUserId","getMerchantStats","getCustomerDetailsByPhoneNumber"]),

    openAwardPointsForm(){
      this.awardPointsForm = true;
      this.redeemPointsForm = false;
      this.$bvModal.show("award-points");
    },
    openRedeemPointsForm(){
      this.awardPointsForm = false;
      this.redeemPointsForm = true;
      this.$bvModal.show("redeem-points");
    },
    onComplete(v) {
      this.customerVerificationCode = v;
    },

    onChange(v) {

    },

    sendVerificationCodeToCustomer(){

      let self = this;
      return new Promise((resolve, reject) => {
        self.$v.$touch();
        if (!self.$v.$invalid) {
          self.isLoading = true;

          this.isLoading = true;

          self.sendVerificationCode({phoneNumber: this.redemption.phone_number})
                  .then(function (verification) {
                    self.isLoading = false;

                    if(verification.customerExists){
                      self.verificationCode = verification.message;

                      resolve(true);
                    }
                    else{
                      reject({message:"Customer does not exist"});
                    }

                  })
                  .catch(function (error) {

                    self.isLoading = false;

                    reject({});

                  });
        }
      })
    },
    validateBeforeSubmitRedemption(){
      let self = this;

      if(this.verificationCode == this.customerVerificationCode) {
        if(parseInt(this.redemption.redemptionValue) >= 1){
          return new Promise((resolve, reject) => {

            self.$v.$touch();
            if (!self.$v.$invalid) {
              self.isLoading = true;

              let userData = JSON.parse(localStorage.getItem("userData"));

              this.redemption.user_id = userData.id;

              self.redeemPointsWebPortal(self.redemption).then((response) => {
                self.isLoading = false;

                resolve(true);

                this.$bvModal.hide("redeem-points");

                self.$bvToast.toast("Loyalty balance credited", {
                  title: 'Success',
                  variant: 'success',
                  autoHideDelay: 5000,
                  appendToast: false
                });

                self.getTransactionsData();

              }).catch(error => {
                self.isLoading = false;
                console.log(error);
                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });
              })
            }
          });
        }
        else{
          this.$bvToast.toast("Redemption amount should be greater than or equal to 1. ", {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });
        }
      } else{
        this.$bvToast.toast("Please enter OTP to proceed ", {
          title: 'Error',
          variant: 'danger',
          autoHideDelay: 5000,
          appendToast: false
        });
      }



    },

    nextStep(e){

    },
    previousStep(e){

    },

    verifyVcode(){
      let self = this;
      return new Promise((resolve, reject) => {
          if(self.verificationCode == self.customerVerificationCode){
            self.verificationCodeVerified = true;
            resolve(true);

            self.getCustomerDetailsByPhoneNumberData();
          }
          else{
            reject({});
          }
      });
    },

    validateBeforeSubmitAwardPoints(){
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if(this.phoneNumberVerified){


          if(this.awardPoints.amount >= 100){
            this.awardCustomerPoints();
          }
          else{
            this.$bvToast.toast("Accrual amount should be greater than or equal to 100", {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });
          }

        }
        else{
          this.getCustomerDetailsByPhoneNumberData();
        }
      }
    },

    getCustomerDetailsByPhoneNumberData(){
      let self = this;

      this.isLoading = true
      this.getCustomerDetailsByPhoneNumber({phoneNumber: self.awardPointsForm ? self.awardPoints.customerPhoneNumber : self.redemption.phone_number})
              .then(function (customer) {

                self.isLoading = false

                if(customer){

                  if(self.awardPointsForm){
                    self.awardErrorMessage = "";
                    self.phoneNumberVerified = true;
                    self.awardPoints.loyaltyId = customer.id;
                  }
                  else {
                    self.redemption.loyaltyId = customer.id;
                  }

                }
                else{
                  self.awardErrorMessage = "Customer does not exist"
                }



              })
              .catch(function (error) {
                self.isLoading = false
                console.log(error);

              })
    },
    awardCustomerPoints(){
      let self = this;

      this.$bvModal.hide("award-points");

      let userData = JSON.parse(localStorage.getItem("userData"));

      this.awardPoints.userId = userData.id;
      this.awardPoints.outletId = this.outlet.id;

      this.isLoading = true;

      this.awardCustomerPointsWebPortal(this.awardPoints)
              .then(function () {

                self.isLoading = false;

                self.$bvToast.toast("Points awarded to customer", {
                  title: 'Success',
                  variant: 'success',
                  autoHideDelay: 5000,
                  appendToast: false
                });

                self.getTransactionsData();

              })
              .catch(function (error) {

                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });
              })
    },
    getOutletByMerchant(){
      let self = this;

      this.isLoading = true
      this.getOutletsByUserId({userId: localStorage.getItem("userId")})
        .then(function (outlets) {

          self.isLoading = false


          self.outlets = outlets;

          if(self.outlets.length > 0){
            self.outlet = self.outlets[0];

            self.getTransactionsData();
            self.getMerchantLoyaltyStatsData();
          }

        })
        .catch(function (error) {
          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },
    openReverseTransactionModal(transaction){

        this.transaction = transaction;

      this.$bvModal.show("reverse-transaction")


    },
    reverseCustomerTransaction(){

      let self = this;

      this.isLoading = true;


      this.reverseTransaction({id: this.transaction.id, reversal_comments: this.reversal_comments, userId: localStorage.getItem("userId") })
        .then(function () {

          self.isLoading = false;

          self.$bvToast.toast("Transaction reversed", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });

          self.getTransactionsData();

        })
        .catch(function (error) {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });
        })
    },
    changePage: function (page) {
      this.pagination.current_page = page;
      this.getTransactionsData();
    },
    getLoyaltyStatsData(){
      let self = this;
      this.getLoyaltyStats()
        .then(function (loyaltyStats) {
          self.loyaltyStats = loyaltyStats;
        })
        .catch(function (error) {
          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },
    getMerchantLoyaltyStatsData(){
      let self = this;

      let outlet_id = 0;

      if(this.outlet != null){
        outlet_id = this.outlet.id;
      }

      this.getMerchantStats({id:outlet_id})
        .then(function (merchantStats) {
          self.merchantStats = merchantStats;
        })
        .catch(function (error) {
          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },
    getRulesData(){
      let self = this;
      this.getRules()
          .then(function (rules) {
            self.rules = rules;

          })
          .catch(function (error) {

          })
    },
    getTransactionsData(){
      let self = this;

      let outlet_id = 0;

      if(this.outlet != null){
        outlet_id = this.outlet.id;
      }

      this.isLoading = true;

      this.getLoyaltyTransactions({page: this.pagination.current_page, from_date:this.start_date,
        to_date:this.end_date,outlet_id:outlet_id, transaction_type:this.transaction_type,
        approval_type: this.approval_type, loyaltyRuleId: this.loyaltyRuleId})
        .then(function (transactions) {

          self.isLoading = false;

          self.transactions = [];

          let transactions_arr = transactions.content;

          transactions_arr.forEach(function (transaction) {


            let single_loyalty_trans = transaction;

            if(transaction.customer != null){
              single_loyalty_trans.phoneNumber = transaction.customer.mobileNumber;
            }
            else{
              single_loyalty_trans.phoneNumber = "";
            }
            self.transactions.push(single_loyalty_trans)
          });


          self.pagination.current_page = transactions.number;
          self.pagination.total = transactions.totalElements;
          self.pagination.per_page = transactions.numberOfElements;
          self.pagination.from = transactions.pageable.offset + 1 ;
          self.pagination.to = transactions.pageable.pageSize;
          self.pagination.last_page = transactions.totalPages;
        })
        .catch(function (error) {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },
    getOutletData(){
      let self = this;

      this.isLoading = true

      this.getOutlets()
        .then(function (outlets) {


          self.outlets = outlets;

          self.getTransactionsData();


        })
        .catch(function (error) {
          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },
    clearFilter(){
      this.start_date = "all";
      this.end_date = "all";
      this.transaction_type = "all";

      if(this.role != 'Merchant'){
        this.outlet = null;
      }

    },
  }
};
</script>

<style >

  .vue-form-wizard .wizard-icon-circle .wizard-icon-container{
    background-color: transparent !important;
  }
  .vue-form-wizard.sm .wizard-nav-pills>li.active>a .stepTitle{
    color:#4AB5E6 !important;
  }


  .vue-form-wizard .wizard-tab-content {
    padding-top: 50px;
  }
  .stepTitle{
    margin-top: -67px;
    color:#4AB5E6;
    font-weight: 600;
  }
  .vue-form-wizard.sm .wizard-icon-circle{
    height: 20px;
    width: 20px;
    background-color: #4AB5E6;
    border: none;
  }
  .vue-form-wizard.sm .wizard-navigation .wizard-progress-with-circle{
    top:14px;
    left:15.5%;
    width: 69.5% !important;
    background-color: #4AB5E6 !important;
    height: 2px !important;
  }
  table tr th{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  fieldset.custom-border {
    border: 1px groove #ddd !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
  }

  legend.custom-border {
    width: auto;
    padding: 0 10px;
    border-bottom: none;
  }
  .vue-step-wizard{
    width: 100% !important;
  }
  .tabStatus{
    background-color: #002563 !important;
  }
  .tabLabel{
    font-weight: bolder;
  }

  label{
    font-size: 14px;
  }

  .vue-form-wizard .wizard-navigation .wizard-progress-with-circle .wizard-progress-bar{
    width: 0% !important;
  }
  .vue-form-wizard .navbar .navbar-nav>li>a.wizard-btn, .vue-form-wizard .wizard-btn{
    background-color: #4AB5E6 !important;
    color:#000 !important;
    border: none;
    margin-top: 20px;

  }

  table td{
    padding: 10px;
  }
</style>
